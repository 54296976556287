import React from 'react'
import Tabs from './Tabs'
import DealOfTheDay from './DealOfTheDay'
import MostPopular from './MostPopular'
import AdGroupHandler from './AdGroupHandler'

export default class HomeTabPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      posts: this.props.posts,
      categories: this.props.categories,
      listedSelection: this.props.posts,
      PopularList: this.props.posts,
      categoryTabs: [{ cat: 'Test Item' }],
    }
  }

  componentDidMount() {
    this.RemakeList()
    this.categoryTabsList()
    this.mostPopular()
  }

  categoryTabsList = () => {
    const categories = this.state.categories
    const categoriesLength = categories.length
    const list = [{ cat: 'Latest' }]
    for (var i = 0; i < categoriesLength; i++) {
      if (
        categories[i].node['name'] !== 'Featured' &&
        categories[i].node['name'] !== 'Blog' &&
        categories[i].node['name'] !== 'Most Popular'
      ) {
        list.push({ cat: categories[i].node['name'] })
      }
    }
    this.setState({ categoryTabs: list })
  }

  mostPopular = () => {
    const postlist = this.state.posts
    const postlistLength = postlist.length
    const list = []
    for (var i = 1; i < postlistLength; i++) {
      if (postlist[i].node['categories'] !== null) {
        const postlistCatLength = postlist[i].node['categories'].nodes.length
        for (var j = 0; j < postlistCatLength; j++) {
          if (
            postlist[i].node['categories']['nodes'][j]['name'] ===
              'Most Popular' &&
            list.length < 10
          ) {
            list.push(postlist[i])
          }
        }
      }
      this.setState({ PopularList: list })
    }
  }
  RemakeList = () => {
    const postlist = this.state.posts
    const postlistLength = postlist.length
    const list = []
    for (var i = 1; i < postlistLength; i++) {
      if (postlist[i].node['categories'] !== null) {
        const postlistCatLength = postlist[i].node['categories'].length
        for (var j = 0; j < postlistCatLength; j++) {
          if (
            postlist[i].node['categories'][j]['name'] === 'Featured' &&
            list.length <= 4
          ) {
            list.push(postlist[i])
          }
        }
      }
    }
    this.setState({ FeaturedList: list })
  }

  render() {
    const PopularList = this.state.PopularList
    const { posts } = this.state
    const categoryTabs = this.state.categoryTabs

    return (
      <div className="bottom-section">
        <div className="container">
          <div className="tabs-home">
            <Tabs posts={posts}>
              {categoryTabs.map(({ cat: tag }, index) => (
                <div label={tag} key={index}>
                  {' '}
                </div>
              ))}
            </Tabs>
          </div>
          <AdGroupHandler style="HomePage Banner Mid" />
          <DealOfTheDay />
          <MostPopular post={PopularList} />
        </div>
      </div>
    )
  }
}
