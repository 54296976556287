import React from 'react'
import BigFeature from './BigFeature'
import FeatureList from './FeatureList'
import AdGroup from './AdGroup'
import styled from '@emotion/styled'

const TopSection = styled('div')`
  .featured-section {
    display: flex;
    flex-direction: row;
    text-align: left;
  }

  .featured-post {
    width: 75%;
    text-align: left;
    transition: 0.2s linear;
    padding: 0 0 15px 15px;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
      transform: scale(1.005);
    }
  }

  .feature-wrapped {
    text-align: left;
    text-decoration: none;
    max-height: 600px;
  }

  .featured-title {
    text-align: left;
    text-decoration: underline;
    text-decoration-color: white;
  }

  .featured-excerpt {
    text-decoration: underline;
    text-decoration-color: white;
  }

  .feature-date {
    text-align: left;
    text-decoration: underline;
    text-decoration-color: white;
  }

  .featured-widget {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: auto;
  }

  .feature-list {
    display: flex;
    flex-direction: column;
    background-color: rgb(248, 244, 244);
  }

  .feature-div-title {
    text-align: center;
    padding-top: 10px;
    color: #a18c78;
    font-weight: bold;
    text-transform: uppercase;
  }

  @media (max-width: 767px) {
    .featured-section {
      flex-wrap: wrap;
    }

    .featured-post {
      order: -1;
      width: 100%;
      padding: 0;
      padding-bottom: 15px;
    }

    .featured-widget {
      height: 60vh;
      overflow: auto;
      width: 100%;
    }

    .featured-list {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    & > .container {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`

const HomePage = () => {
  return (
    <TopSection>
      <div className="container">
        <AdGroup
          slug="homepage-banner-top"
          carousel={true}
          carouselDelay={8000}
        />
        <div className="featured-section">
          <div className="featured-widget">
            <FeatureList />
          </div>
          <BigFeature />
        </div>
      </div>
    </TopSection>
  )
}

export default HomePage
