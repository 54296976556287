import { graphql, useStaticQuery } from 'gatsby'

const usePostImages = (source) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { regex: "/(post__)/" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 550, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const image = data.allFile.nodes.filter((image) => {
    return image.childImageSharp.fluid.src.includes(source)
  })
  return image.length ? image[0].childImageSharp : null
}

export default usePostImages
