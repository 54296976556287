import { graphql, useStaticQuery } from 'gatsby'

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        limit: 5
        sort: { fields: date, order: DESC }
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Featured" } } } }
        }
      ) {
        edges {
          node {
            id
            title
            excerpt
            slug
            featuredImage {
              node {
                title
                sourceUrl
                slug
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 150, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "MM/DD/YYYY")
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `)
  return data
}

export default Features
