import React from 'react'
import styled from '@emotion/styled'
import AdGroupHandler from './AdGroupHandler'

const DealOfTheDayWrapper = styled('div')`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: xx-large;
  font-weight: 400;
  border: medium;
  border-color: #a18c78;
  overflow: hidden;
  background-color: rgb(226, 222, 222);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 30px 25px;

  h2.title {
    margin: 0;
    font-size: 34px;
    text-transform: uppercase;
  }

  h3.title {
    font-size: 30px;
  }

  .dod-padding {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .dod-cards {
    display: grid;
    grid-column: 100%;
    grid-row: 80% 20%;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: white;
    padding: 15px;
    height: 495px;
  }

  .dod-button {
    transition: 0.2s linear;
    cursor: pointer;
    padding: 13px 15px;
    margin: auto;
    color: #a18c78;
    font-size: large;
    font-weight: bold;
    background-color: white;
    border-color: rgb(141, 141, 141);
    border-radius: 12px;
    width: 75%;

    &:hover {
      background-color: #a18c78;
      color: white;
    }
  }

  .dod-outer-div {
    transition: 0.2s linear;
    background-color: white;
    padding-bottom: 15px;

    &:hover {
      box-shadow: 0px 0 9px 4px rgba(0, 0, 0, 0.15);
      cursor: pointer;
    }
  }

  .react-multi-carousel-list {
    padding-bottom: 25px;
  }

  .blog-dod-cards {
    display: grid;
    grid-column: 100%;
    grid-row: 80% 20%;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: white;
    padding: 15px;
    height: 330px;
  }

  @media (max-width: 767px) {
    h2.title {
      font-size: 30px;
    }

    h3.title {
      font-size: 25px;
    }

    .blog-dod-cards {
      height: 525px;
    }
  }
`

const DealOfTheDay = () => (
  <DealOfTheDayWrapper className="deal-of-the-day">
    <h2 className="title">Deals of the day</h2>
    <AdGroupHandler style="Deal of the Day" />
  </DealOfTheDayWrapper>
)

export default DealOfTheDay
