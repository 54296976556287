import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'
import Home from '../components/HomeComp'
import HomeTabs from '../components/HomeTabs'
import Layout from '../components/Layout'
import PostContext from '../components/PostContext'
import AdGroup from '../components/AdGroup'
import KlaviyoForm from '../components/WidgetKlaviyo'
import styled from '@emotion/styled'

const HomePageWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;

  .content-side {
    width: 75%;
  }

  .ads-side {
    width: 25%;
  }
`

const HomePage = (props) => {
  const featurelist = props.data.allWpPost
  const menus = props.data.allWpMenu.edges
  const categories = props.data.allWpCategory.edges
  const { url: siteUrl } = useSiteMetadata()

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={`${siteUrl}`} />
      </Helmet>
      <PostContext.Consumer>
        {({ allWpPost: { edges: posts } }) => (
          <HomePageWrapper>
            <KlaviyoForm
              className="klaviyo-form"
              formClass="klaviyo-form-Sgds37"
              title="SUBSCRIBE TO RECEIVE OUR LATEST BLOG POSTS"
            />
            <div className="content-side">
              <Home posts={posts} menus={menus} featurelist={featurelist} />
              <HomeTabs posts={posts} categories={categories} />
            </div>
            <div className="ads-side">
              <AdGroup slug="homepage-banner-sidebar" />
            </div>
          </HomePageWrapper>
        )}
      </PostContext.Consumer>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query indexQuery {
    allWpPost(
      limit: 5
      sort: { fields: date, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Featured" } } } }
      }
    ) {
      edges {
        node {
          id
          title
          excerpt
          slug
          featuredImage {
            node {
              title
              sourceUrl
              slug
              localFile {
                childImageSharp {
                  fluid(maxWidth: 150, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          date(formatString: "MM/DD/YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    allWpCategory {
      edges {
        node {
          name
        }
      }
    }
    allWpMenu {
      edges {
        node {
          name
          menuItems {
            nodes {
              url
              label
              title
            }
          }
        }
      }
    }
  }
`
