import React, { Component } from 'react'
import Tab from './Tab'
import TagList from './TagList'

export default class TabsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 'Latest',
      listedSelection: this.props.posts,
      posts: this.props.posts,
      latestTab: true,
    }
  }
  componentDidMount() {
    this.SelectedListed()
  }
  componentDidUpdate() {
    const activeTab = this.state.activeTab
    const latestTab = this.state.latestTab
    if (activeTab === 'Test Item') {
      this.setState({ activeTab: this.props.children[0].props.label })
    }
    if (
      this.state.listedSelection[0].node['title'].includes(activeTab) !==
        true &&
      activeTab != 'Latest'
    ) {
      this.SelectedListed()
    }
    if (activeTab === 'Latest' && latestTab !== true) {
      this.SelectedListed()
    }
  }
  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab })
  }

  SelectedListed = () => {
    const activeTab = this.state.activeTab
    const posts = this.state.posts
    const postsLength = posts.length
    let list = []
    if (activeTab != 'Latest') {
      for (var i = 0; i < postsLength; i++) {
        if (
          posts[i].node['title'].includes(activeTab) === true &&
          list.length < 9
        ) {
          list.push(posts[i])
        }
      }
    } else {
      for (var i = 1; i < 10; i++) {
        list.push(posts[i])
      }
    }
    if (activeTab === 'Latest') {
      this.setState({
        listedSelection: list,
        latestTab: true,
      })
    } else {
      this.setState({
        listedSelection: list,
        latestTab: false,
      })
    }
  }

  render() {
    const list = this.state.listedSelection
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this
    return (
      <div className="tabs">
        <ol className="tab-list" id="recent">
          {children.map((child) => {
            const { label } = child.props

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            )
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined
            return child.props.children
          })}
          <TagList posts={list} activeTab={activeTab} />
        </div>
      </div>
    )
  }
}
