import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PostImageThumbnail from './PostImageThumbnail'

export default class TagList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: this.props.activeTab,
      posts: this.props.posts,
    }
  }

  render() {
    const { posts, activeTab } = this.props

    const PostListElement = styled('section')`
      a {
        padding: 15px;
        border: 2px solid transparent;
        text-decoration: none;
        transition: 0.2s linear;
        display: block;
        height: 100%;

        &:hover {
          border-color: #a18c78;
          transform: scale(1.03);
        }
      }

      h2 {
        font-size: 20px;
        text-transform: uppercase;
      }

      .image-wrapper {
        position: relative;
        
        height: 201px;
  
        &>div,
        &>div * {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .post-date {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background: #a18c78;
        color: #fff;
        padding: 7px 10px;
        font-weight: bold;
      }

      .post-elem {
        padding-left 5px;
        padding-right: 5px;
        margin-bottom: 2%;
      }

      .read-more {
        text-transform: uppercase;
        color: #a18c78;
        font-weight: bold;
        border-bottom: 2px solid #a18c78;
      }

      @media (max-width: 991px) {
        .image-wrapper {
          height: 18vw;
        }
      }

      @media (max-width: 767px) {
        .image-wrapper {
          height: 25vw;
        }
      }

      @media (max-width: 480px) {
        .image-wrapper {
          height: 50vw;
        }
      }
    `
    const PostImage = styled(Img)``

    return (
      <div className="container">
        <PostListElement>
          <div className="flex-row flex-wrap-sm flex-wrap-xs col-50">
            {posts.slice(0, 9).map(({ node: post }) => (
              <div className="post-elem flex-col-4" key={post.id}>
                <Link to={`/blog/${post.slug}/`}>
                  <div className="image-wrapper">
                    {post.featuredImage?.node?.localFile?.childImageSharp
                      ?.fluid ? (
                      <Img
                        fluid={
                          post.featuredImage.node.localFile.childImageSharp
                            .fluid
                        }
                        alt={post.title}
                      />
                    ) : (
                      <PostImageThumbnail />
                    )}
                  </div>
                  <div className="top-part">
                    <h2
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: post.title,
                      }}
                    ></h2>
                  </div>
                  <div className="bottom-part-tag">
                    <span className="home-page-date">{post.date}</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </PostListElement>
        {activeTab != 'Latest' ? (
          <div className="center-over-div-fix">
            <Link
              className="button1"
              to={`/search/?q=${activeTab.toLowerCase()}`}
            >
              See all {activeTab} Posts
            </Link>
          </div>
        ) : (
          <div className="center-over-div-fix">
            <Link className="button1" to={`/page/1`}>
              See all Latest Posts
            </Link>
          </div>
        )}
      </div>
    )
  }
}
