import React from 'react'
import Img from 'gatsby-image'
import Features from '../hooks/use-feature-list'
import { Link } from 'gatsby'
import PostImageThumbnail from './PostImageThumbnail'
import Feature from '../hooks/use-first-post'

const FeatureList = () => {
  const everyPost = Feature()
  const features = Features()
  const firstPost = everyPost.allWpPost.edges[0].node
  const totalPosts = () => {
    const allPosts = features.allWpPost.edges
    const postlistLength = allPosts.length
    const list = []
    for (var i = 0; i < postlistLength; i++) {
      if (
        allPosts[i].node['categories'].nodes !== null &&
        allPosts[i].node.id !== firstPost.id
      ) {
        const postlistCatLength = allPosts[i].node['categories'].nodes.length
        for (var j = 0; j < postlistCatLength; j++) {
          if (
            allPosts[i].node['categories'].nodes[j]['name'] === 'Featured' &&
            list.length <= 3
          ) {
            list.push(allPosts[i])
          }
        }
      }
    }

    return list
  }

  const list = totalPosts()
  const FeaturedList = list

  return (
    <div className="feature-list">
      <div className="feature-div-title">Featured Posts</div>
      {FeaturedList.length <= 10 ? (
        <div>
          {FeaturedList.map(({ node: post }, index) => (
            <div key={index}>
              <Link className="hundred" to={`/blog/${post.slug}/`} key={index}>
                {post.featuredImage?.node?.localFile?.childImageSharp?.fluid ? (
                  <div className="img4list">
                    <Img
                      fluid={
                        post.featuredImage.node.localFile.childImageSharp.fluid
                      }
                      alt={post.title}
                    />
                  </div>
                ) : (
                  <PostImageThumbnail />
                )}

                <div className="center">
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: post.title,
                    }}
                  />
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}
export default FeatureList
