import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PostImageThumbnail from './PostImageThumbnail'
import Feature from '../hooks/use-first-post'

const FeaturePost = () => {
  const theOne = Feature()
  const Featuredpost = theOne.allWpPost.edges[0].node

  return (
    <div className="featured-post" key={Featuredpost['id']}>
      <Link to={`/blog/${Featuredpost['slug']}/`}>
        <div className="feature-wrapped">
          {Featuredpost['featuredImage'].node?.localFile?.childImageSharp
            ?.fluid ? (
            <Img
              className="main-feature"
              fluid={
                Featuredpost['featuredImage'].node.localFile.childImageSharp
                  .fluid
              }
              alt={Featuredpost['title']}
            />
          ) : (
            <PostImageThumbnail />
          )}
        </div>
        <div className="top-part">
          <h2
            className="featured-title"
            dangerouslySetInnerHTML={{
              __html: Featuredpost['title'],
            }}
          ></h2>
          <h4 className="feature-date">{Featuredpost['date']}</h4>
        </div>
        <div className="bottom-part">
          <div
            className="featured-excerpt"
            dangerouslySetInnerHTML={{
              __html: Featuredpost.excerpt
                .replace(/class="more-link.*/, '')
                .slice(0, 350)
                .concat('...'),
            }}
          />
          <span className="read-more">Read More</span>
        </div>
      </Link>
    </div>
  )
}
export default FeaturePost
