import React from 'react'
import Image from 'gatsby-image'
import { css } from '@emotion/react'
import usePostImages from '../hooks/use-post-images'

const PostImageThumbnail = () => {
  const postImage = usePostImages('post__thumbnail.jpg')

  return (
    <Image
      fluid={postImage.fluid}
      alt="REFactorTactical Post Thumbnail"
      css={css`
        box-shadow: 0 0 7px #333;
      `}
    />
  )
}

export default PostImageThumbnail
