import React from 'react'
import styled from '@emotion/styled'

const FormContainer = styled('div')`
  width: 100%;
  background: #4a4949;
  margin: -25px 0 15px;

  button {
    background: #a18c78 !important;
    border-color: #a18c78 !important;
  }

  .container {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    padding: 10px 15px;

    & > div {
      width: 50%;
    }
  }

  p {
    margin: 0;
    text-align: center;
  }

  form {
    padding: 0 !important;
  }

  @media (max-width: 991px) {
    .container {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }

    p {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 480px) {
    margin-bottom: 5px;

    p {
      font-size: 14px;
    }
  }
`

const KlaviyoForm = ({ title, formClass }) => {
  return (
    <FormContainer>
      <div className="container">
        <div>
          <p>{title}</p>
        </div>
        <div className={formClass}></div>
      </div>
    </FormContainer>
  )
}

export default KlaviyoForm
