import { graphql, useStaticQuery } from 'gatsby'

const Feature = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(limit: 1, sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            title
            excerpt
            slug
            featuredImage {
              node {
                title
                sourceUrl
                slug
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "MM/DD/YYYY")
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `)
  return data
}

export default Feature
